import { useState, useEffect } from "react";
import styles from "./store-notice.module.css";
import Marquee from "react-fast-marquee";
import NoticeCountdown from "../notice-countdown/notice-countdown";

const StoreNotice = () => {
  const [coupons, setCoupons] = useState([]);
  const [showCountdown, setShowCountdown] = useState(false);

  const fetchDataBanner = async () => {
    const api_url = process.env.API_URL;
    const response = await fetch(`${api_url}api/coupons/active`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const data = await response.json();
      const noticedCoupons = data
        .filter((coupon) => coupon.coupon_is_noticed)
        .map((coupon) => ({
          id: coupon.id,
          name: coupon.coupon_name,
          notice_desc: coupon.coupon_notice_desc,
          min_purchase: coupon.coupon_min_purchase,
          start_date: new Date(coupon.coupon_start_date),
          end_date: new Date(coupon.coupon_end_date),
        }));
      setCoupons(noticedCoupons);
    }
  };

  useEffect(() => {
    const checkCountdownVisibility = () => {
      if (coupons && coupons.length > 0) {
        const now = new Date();

        for (const coupon of coupons) {
          const couponStartDate = coupon.start_date;
          const couponEndDate = coupon.end_date;
          if (now >= couponStartDate && now < couponEndDate) {
            setShowCountdown(true);
            return;
          }
        }
        setShowCountdown(false);
      }
    };

    checkCountdownVisibility();
    const intervalId = setInterval(checkCountdownVisibility, 1000);

    return () => clearInterval(intervalId);
  }, [coupons]);

  useEffect(() => {
    fetchDataBanner();
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        {coupons && showCountdown ? (
          coupons.map((coupon, index) => (
            <div key={index}>
              <NoticeCountdown
                startDate={coupon.start_date.toISOString().slice(0, 19)}
                endDate={coupon.end_date.toISOString().slice(0, 19)}
                discountNotice={`${coupon.notice_desc} | Kupon: ${coupon.name}`}
                voucherName={
                  coupon.min_purchase === null || coupon.min_purchase === 0
                    ? "No Min. Purchase"
                    : `Min. Purchase ${Number(
                        coupon.min_purchase
                      ).toLocaleString("id-ID")}`
                }
              />
            </div>
          ))
        ) : (
          <Marquee pauseOnHover>
            <div className={styles.store_content}>
              FREE shipping all around Indonesia | Ready Stock | Money back
              guarantee | Re-sell & Return | Know more about this{" "}
              <a href="https://theaurumlab.com/faqs">here</a>
            </div>
          </Marquee>
        )}
      </div>
      {/* <Marquee pauseOnHover> */}
      {/* <div className={styles.point_notice}>
        <div className={styles.store_content}>
          Aurum Lab&apos;s points will be expired on Dec 31st 2024. Use it as an
          extra discount now! Check your points{" "}
          <a href="https://theaurumlab.com/account#point" target="_blank">
            here
          </a>
        </div>
      </div>  for point notification*/}
      {/* </Marquee> */}
    </>
  );
};

export default StoreNotice;
